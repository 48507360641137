<template>
  <div class="home">
    <div id="container1" class="container1">
      <div class="top-box">
        <img src="/imgs/home/logo.png" alt class="logo">
        <div class="a-box">
          <div class="box">
            <div>
              <a href="#" @click="goPage('/aboutus')">关于无界SaaS</a>
              <a href="http://unih5.wujieuni.com/newh5/#/download" target="view_window">下载无界SaaS</a>
              <a href="#" class="official-account" @click="QRcode1 = !QRcode1">关注公众号</a>
            </div>
          </div>
          <img
            v-if="QRcode1"
            src="/imgs/home/QRcode1.png"
            alt
            class="QRcode1"
          >
          <div class="menu-icon" sign="1">
            <div class="line line-1" />
            <div class="line line-2" />
            <div class="line line-3" />
          </div>
        </div>
      </div>
      <img src="/imgs/home/数字一卡通.png" alt class="twinkle twinkle1">
      <img src="/imgs/home/生态云仓.png" alt class="twinkles twinkle2">
      <img src="/imgs/home/家居云仓.png" alt class="twinkle twinkle3">
      <img src="/imgs/home/链物云仓.png" alt class="twinkles twinkle4">
      <img src="/imgs/home/综合云仓.png" alt class="twinkle twinkle5">
      <img src="/imgs/home/GlobalLink.png" alt class="twinkles twinkle6">
      <img src="/imgs/home/omyo.png" alt class="twinkle twinkle7">
      <div class="container1-box">
        <img
          src="/imgs/home/container1-logo.png"
          alt
          class="container1-logo"
        >
        <img
          src="/imgs/home/container1-text.png"
          alt
          class="container1-logo1"
        >
        <div class="container1-text">
          <div class>
            生而全球化的无界SaaS是未来产业互联网和物联网的综合应用体现，作为一个
            <div class>全新的物种，独创的把商业模式与工具应用融合为一体。</div>
          </div>
          <div class>
            无界SaaS是集“云、网、店、端、人、单、群、社交、
            <div class>自媒体社交”等合一的复合经济体。</div>
          </div>
        </div>
      </div>
      <div class="stars stars1" />
      <div class="stars stars2" />
      <div class="stars stars3" />
      <div class="stars stars4" />
      <div class="stars stars5" />
      <div class="stars stars6" />
      <div class="stars stars7" />
      <div class="stars stars8" />
      <div class="stars stars9" />
      <div class="stars stars10" />
      <div class="stars stars11" />
      <div class="stars stars12" />
      <div class="stars stars13" />
      <div class="stars stars14" />
    </div>
    <div class="container2">
      <div class="container2-box">
        <div class="text-box">
          <div class>
            <img src="/imgs/home/container2-text1.png" alt>
            <div class="text">
              <div class>无界SaaS是无界公司独创的多维商业模式工具，</div>
              <div class>是一个新时代的全新物种。它既是创新商业模式，</div>又是赋能各行各业的中小微企业、个体工商户、经营创业者数字化转型升级的工具。
            </div>
            <a href="http://unih5.wujieuni.com/newh5/#/download" target="view_window">
              <div class="but">了解更多</div>
            </a>
          </div>
        </div>
        <img src="/imgs/home/container2-img.png" alt>
      </div>
      <div class="container2-box">
        <img src="/imgs/home/container2-img1.png" alt>
        <div class="text-box text-box1">
          <div class>
            <img src="/imgs/home/container2-text2.png" alt>
            <div class="text">
              <div class>Uni02 综合云仓</div>
              <div class>Uni04 链物云仓</div>
              <div class>Uni07 OMYO线上线下一体云仓</div>
              <div class>Unixxx......</div>
              <div class>无数分布式经营、链接联合、协同合作的无界数字云仓</div>
            </div>
            <a href="http://www.uni02.com/" target="view_window">
              <div class="but">了解更多</div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="container3">
      <img src="/imgs/home/container3.jpg" alt class="container3-bg">
      <div class="container3-box">
        <img src="/imgs/home/container3-text.png" alt>
        <div class="text-box">
          <div class>打破边界，寻找志同道合的你，</div>
          <div class>构建利益共同体、事业共同体、命运共同体，做更好的自己！</div>
          <div class>共同实现自我价值，同时创造出独一无二的价值，</div>
          <div class>获得无边界利润来源，共同实现无边界经营创业！</div>
        </div>
      </div>
    </div>
    <div class="container4">
      <img src="/imgs/home/container4-img1.png" alt>
      <div class="news-list">
        <div v-for="i in articleList" :key="i.articleId" class="list-item" @click="news('https://unih5.wujieuni.com/newh5/#/news/details?articleId='+i.articleId)">
          <div class="img-box">
            <img :src="i.titleImageUrl" alt>
          </div>
          <div class="item-box">
            <div class="time">{{ i.createTime }}</div>
            <div class="title">{{ i.title }}</div>
          </div>
        </div>
      </div>
      <div class="but news" @click="QRcode2 = !QRcode2">
        了解更多
        <div v-if="QRcode2" class="QRcode2-box">
          <img src="/imgs/home/QRcode1.png" alt class="QRcode2">
        </div>
      </div>
    </div>
    <div class="container5">
      <img src="/imgs/home/container5.jpg" alt class="container5-bg">
      <div class="container5-box">
        <div class="left-box">
          <img src="/imgs/home/logo.png" alt>
          <div class="container5-left-text">CCTV《匠心》栏目中华民族100家创新型高新技术企业</div>
          <div class="codeList">
            <div class="codeItem">
              <img src="/imgs/home/codeItem1.png" alt>
              <div class="code-text">
                无界SaaS公众号
              </div>
            </div>
            <div class="codeItem">
              <img src="/imgs/home/codeItem2.jpg" alt>
              <div class="code-text">Uni无界云仓公众号</div>
            </div>
            <div class="codeItem">
              <img src="/imgs/home/codeItem3.jpg" alt>
              <div class="code-text">
                无界SaaS安卓端
              </div>
            </div>
            <div class="codeItem">
              <img src="/imgs/home/codeItem4.png" alt>
              <div class="code-text">
                无界SaaS苹果端
              </div>
            </div>
          </div>
        </div>
        <div class="right-box">
          <div class="right-top-box">
            <div class="about-list" @click="goPage('/aboutus')">
              <div class="about-title">关于我们</div>
              <div class="about-item">企业简介</div>
              <div class="about-item">企业文化</div>
              <div class="about-item">创新优势</div>
              <div class="about-item">资质证书</div>
            </div>
            <div class="about-list" @click="goPage('/joinus')">
              <div class="about-title">招贤纳士</div>
              <div class="about-item">社会招聘</div>
              <div class="about-item">校园招聘</div>
            </div>
            <div class="about-list" @click="goPage('/contactus')">
              <div class="about-title">联系我们</div>
              <div class="about-item">客服热线</div>
              <div class="about-item">合作洽谈</div>
            </div>
            <div class="about-list" @click="goPage('/privacy')">
              <div class="about-title">法律信息</div>
              <div class="about-item">隐私政策</div>
            </div>
          </div>
          <div class="recordNo">
            Copyright ©2014-2021 UNI02.COM All Rights Reserved
            <a
              href="https://beian.miit.gov.cn/?spm=a2cmq.17629970.J_9220772140.110.f0d079fewWqZNB#/Integrated/index"
              target="_blank"
            >粤ICP备2023005663号-1</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap/all'
import axios from 'axios'
export default {
  name: 'Home',
  data() {
    return {
      QRcode1: false,
      QRcode2: false,
      articleList: []
    }
  },
  created() {
    if (!this.is_pc()) {
      this.$router.push('/mobile')
    }
    axios.get('//uniapi.wujieuni.com/v1/shareArticle/list?categoryId=19&page=1&pageSize=3').then((res) => {
      this.articleList = res.data.body.page.list
    }).catch((error) => {
      console.log(error)
    })
  },
  mounted() {
    gsap.to('.twinkle', {
      duration: 2,
      scale: 'random(0.7,1)',
      repeat: -1,
      yoyo: true,
      opacity: 1
    })
    setTimeout(() => {
      gsap.to('.twinkles', {
        duration: 2,
        scale: 'random(0.7,1)',
        repeat: -1,
        yoyo: true,
        opacity: 1
      })
      gsap.to('.stars', {
        duration: 2,
        scale: 'random(0.7,1)',
        repeat: -1,
        yoyo: true,
        opacity: 1
      })
    }, 1000)
  },
  methods: {
    goPage(path, key) {
      this.$router.push({
        path: path
      })
    },
    is_pc() {
      var os = [
        'Android',
        'iPhone',
        'Windows Phone',
        'iPod',
        'BlackBerry',
        'MeeGo',
        'SymbianOS'
      ]
      var info = navigator.userAgent
      var len = os.length
      for (var i = 0; i < len; i++) {
        if (info.indexOf(os[i]) > 0) {
          return false
        }
      }
      return true
    },
    news(link) {
      window.open(link)
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  overflow: hidden;
  text-align: left;
}
.top-box {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  justify-content: space-between;
  padding: 10px 6%;
  z-index: 10;
  .logo {
    width: 0.5rem;
    margin-top: 0.1rem;
  }
  .a-box {
    display: flex;
    align-items: center;
    position: relative;
    a {
      color: #fff;
      margin-right: 20px;
    }
    .menu-icon {
      margin-top: 7px;
      cursor: pointer;
      display: none;
    }
    .line {
      width: 25px;
      height: 4px;
      background-color: #fff;
      margin-bottom: 5px;
      border-radius: 50px;
    }
    .line-2 {
      background-color: rgba(255, 255, 255, 0.6);
    }
    .line-3 {
      background-color: rgba(255, 255, 255, 0.3);
    }
    .box {
      overflow: hidden;
      div {
        width: 100%;
      }
    }
  }
}

a:link {
  color: #fff;
  text-decoration: none;
}

a:visited {
  color: #fff;
  text-decoration: none;
}

a:hover {
  color: #fff;
  background-color: none;
  text-decoration: none;
}

.QRcode1 {
  position: absolute;
  width: 100px;
  right: 6px;
  top: 33px;
}

.container1 {
  width: 100%;
  position: relative;
  background-color: #002fa7;
  height: calc(120vh);
  .stars {
    width: 3px;
    height: 3px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 4px 1px #fff;
    position: absolute;
  }
  .stars1 {
    left: 2%;
    top: 15%;
    width: 1px;
    height: 1px;
  }
  .stars2 {
    left: 25%;
    top: 2%;
  }
  .stars3 {
    right: 5%;
    top: 2%;
  }
  .stars4 {
    right: 40%;
    top: 8%;
  }
  .stars5 {
    right: 45%;
    top: 15%;
  }
  .stars6 {
    left: 12%;
    top: 29%;
    width: 1px;
    height: 1px;
  }
  .stars7 {
    left: 16%;
    top: 35%;
  }
  .stars8 {
    right: 14%;
    top: 27%;
  }
  .stars9 {
    right: 3%;
    top: 39%;
  }
  .stars10 {
    left: 39%;
    top: 44%;
  }
  .stars11 {
    left: 5%;
    top: 48%;
  }
  .stars12 {
    left: 57%;
    top: 56%;
  }
  .stars13 {
    right: 17%;
    top: 52%;
    width: 3px;
    height: 3px;
    box-shadow: 0 0 4px 3px #fff;
  }
  .stars14 {
    left: 18%;
    bottom: 31%;
  }
}

.bg-img {
  width: 100%;
  display: block;
}

.container1-box {
  position: absolute;
  top: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.container1-logo {
  margin-bottom: 2%;
}
.container1-logo1 {
  margin-left: 0.65rem;
}

.container1-text {
  width: 40%;
  color: #fff;
  text-align: center;
  font-size: 18px;
  margin-top: -0.3rem;
  & > div {
    margin-top: 5%;
    line-height: 0.18rem;
  }
}

.container2 {
  padding: 5% 10%;
}

.container2-box {
  display: flex;
  .text-box {
    padding-right: 0.3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .text {
      color: #808080;
      font-size: 16px;
      margin: 60px 0;
      line-height: 0.15rem;
    }
  }
  .text-box1 {
    padding-left: 0.3rem;
    padding-right: 0;
  }
}

.but {
  color: #fff;
  background-color: #002fa7;
  padding: 8px 40px;
  border-radius: 50px;
  display: inline-block;
  cursor: pointer;
}

.container3 {
  position: relative;
}

.container3-bg {
  display: block;
}

.container3-box {
  position: absolute;
  top: 21%;
  width: 100%;
  text-align: center;
  color: #fff;
  .text-box {
    font-size: 16px;
    line-height: 33px;
    margin-top: 0.2rem;
  }
}

.container4 {
  padding: 5% 10%;
  text-align: center;
}

.news-list {
  display: flex;
  margin: 3% 0;
  height: 40vh;
}

.list-item {
  flex: 1;
  cursor: pointer;
  padding: 0 0.1rem;
  height: 100%;
  .img-box{
    display: flex;
    align-items: center;
    height: 78%;
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .item-box {
    text-align: left;
    .title {
      margin-top: 10px;
    }
  }
}

.item-box .time {
  margin-top: 10px;
  color: #7d7d7d;
}

.container5 {
  position: relative;
  color: #fff;
}

.container5-bg {
  display: block;
}

.container5-box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding: 3% 10%;
  .left-box {
    flex: 1;
    color: #b8ccff;
    .codeList {
      display: flex;
    }
  }
  .codeList {
    padding-right: 18%;
  }
  .right-box {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

.container5-left-text {
  margin: 20px 0;
}

.container5-box .left-box .codeItem {
  flex: 1;
  text-align: center;
  font-size: 13px;
}

.right-top-box {
  display: flex;
  flex: 1;
  .about-list {
    flex: 1;
    cursor: pointer;
    .about-title {
      font-size: 18px;
    }
    .about-item {
      margin-top: 0.1rem;
      color: #b8ccff;
    }
  }
}

.recordNo {
  text-align: center;
  color: #b8ccff;
  a {
    color: #b8ccff;
  }
}

.twinkle1 {
  position: absolute;
  top: 25%;
  left: 5%;
}

.twinkle2 {
  position: absolute;
  top: 12%;
  left: 18%;
}

.twinkle3 {
  position: absolute;
  top: 8%;
  right: 18%;
}

.twinkle4 {
  position: absolute;
  bottom: 5%;
  left: -1.5%;
}

.twinkle5 {
  position: absolute;
  bottom: 8%;
  left: 25%;
}

.twinkle6 {
  position: absolute;
  bottom: 3%;
  right: 18%;
}

.twinkle7 {
  position: absolute;
  bottom: 25%;
  right: 5%;
}

.news {
  position: relative;
  .QRcode2-box {
    padding: 15px;
    border-radius: 6px;
    box-shadow: 0 0 5px #999;
    position: absolute;
    left: 8px;
    top: 40px;
    background-color: #fff;
    z-index: 2;
  }
  .QRcode2 {
    width: 100px;
  }
}
</style>
